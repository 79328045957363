.footer-container {
  bottom: 0;
  width: 100%;
  height: 125px;
  padding-top: 30px;
  background: #282828;
  display: flex;
  justify-content: center;
}

.footer-buttons {
  margin: 12px;
  height: 38px;
}

.navbar {
  position: fixed;
  width:  100%;
  z-index: 999;
  top: 0;
}

.dropdown-item:active,
.dropdown-item.active {
  background-color: grey !important;
}

.dropdown-item:focus {
  background-color: grey !important;
}

@media (max-width: 400px) {
  #newsletter-button {
    display: none;
  }
}
