.content-container {
  margin-top: 48px;
   height: 100%;
   min-height: 100vh; 
}

.homeButton {
  background-color: transparent;
  border: 0;
}

.homeButton:active .homeButton:focus {
  background-color: transparent;
  outline: 0 !important;
}

.homeButton:focus {
  outline: 0 !important;
}

.homeTitle {
  padding-top: 30px;
  padding-bottom: 30px;
}

.centeredRow {
  display: flex;
  justify-content: center;
  padding-right: 30px;
  padding-left: 30px;
}

.homeImage {
  background: url("../../img/header2.jpg") no-repeat;
}

.retreatImage {
  background: url("../../img/retreatsHeader1.jpg") no-repeat;
}

.classesImage {
  background: url("../../img/classesHeader1.jpg") no-repeat;
}

.blogImage {
  background: url("../../img/blogHeader1.jpg") no-repeat;
}

.aboutImage {
  background: url("../../img/aboutHeader1.jpg") no-repeat;
}

.headerImage {
  margin-top:48px;
  background-size: cover;
  background-position: top right;
  min-height: 150px;
}

@media (min-width: 300px) {
  .headerImage {
    min-height: 200px;
  }
}

@media (min-width: 500px) {
  .headerImage {
    min-height: 300px;
  }
}

@media (min-width: 800px) {
  .headerImage {
    min-height: 400px;
  }
}

@media (min-width: 1000px) {
  .headerImage {
    min-height: 450px;
  }
}

@media (min-width: 1200px) {
  .headerImage {
    min-height: 500px;
  }
}

@media (min-width: 1500px) {
  .headerImage {
    min-height: 600px;
  }
}

@media (min-width: 1700px) {
  .headerImage {
    min-height: 700px;
  }
}
@media (min-width: 1920px) {
  .headerImage {
    min-height: 869px;
    background-attachment: fixed;
  }
}
